html,
* {
  box-sizing: border-box;
}

/* input[type="text"] {
  font-size: 24px;
  letter-spacing: 1rem;
  font-weight: 500;
} */
